<template>
  <div class="xiaoHuTravel-mobile" v-if="isMobile">
    <img src="../assets/img/banner_xhcx.png" class="img-banner">
    <div class="main-content">
      <div class="row-me row-center tab-div">
        <div class="column-me center-all left-div">
          <div class="title1">小狐出行</div>
          <div class="title2">XIAOHU TRAVEL</div>
        </div>
        <div class="row-me row-center flex-wrap flex1">
          <div class="title0" :class="{'title0-active':tabIndex===0}" @click="clickIndex(0)">品牌介绍</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===1}" @click="clickIndex(1)">项目优势</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===2}" @click="clickIndex(2)">运营优势</div>
        </div>
      </div>

      <!--品牌介绍-->
      <div v-show="tabIndex===0" class="column-me row-center">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">智能代步车引领者</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">INTELLIGENT SCOOTER LEADER</div>
        <div class="card-div-leader margin-top30">
          <img src="../assets/img/img_xhcx.png" class="img1" draggable="false">
          <div class="flex1 margin-left20 margin-right10 margin-top20 text1">
            “小狐出行”隶属于红尾狐企业旗下品牌板块之一，专注服务于景区代步车业务板块。数年内已深耕22个省市，落地上百余个项目，
            犹如业界的一匹黑马。“小狐出行”致力于景区+物联网模式开发和实施，拥有实力雄厚自主科技研发团队，集合机电工程、汽车设计、
            网络控制系统等各方面人才，在代步车的设计上精益求精，不断创新，为景区提供高性能、高安全性的代步车。后疫情时代，
            代步车作为景区标配二销项目的新趋势，2023年“小狐出行”将在全国景区重点推广!
          </div>
        </div>
        <div class="row-me center-all margin-top80">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">品牌文化</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">XIAOHU CULTURE</div>
        <div class="card-div-culture row-me row-center margin-top35">
          <img src="../assets/img/01.png" class="img1" draggable="false">
          <div class="margin-left20 margin-right20 flex1">理念：打造景区共享物联网代步车，解锁景区出游新方式</div>
        </div>
        <div class="card-div-culture row-me row-center margin-top20">
          <img src="../assets/img/02.png" class="img1" draggable="false">
          <div class="margin-left20 margin-right20 flex1">使命：解决景区痛点，改变游客驾驶体验</div>
        </div>
        <div class="card-div-culture row-me row-center margin-top20">
          <img src="../assets/img/03.png" class="img1" draggable="false">
          <div class="margin-left20 margin-right20 flex1">愿景：让游客出游体验更便捷、更舒适</div>
        </div>

      </div>
      <!--项目优势-->
      <div v-show="tabIndex===1" class="column-me row-center">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">项目优势</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">PROJECT ADVANTAGES</div>
        <img src="../assets/img/bg_xhcx_1.png" class="img-project" draggable="false">
        <img src="../assets/img/bg_xhcx_2.png" class="img-project" draggable="false">
        <img src="../assets/img/bg_xhcx_3.png" class="img-project" draggable="false">
      </div>
      <!--运营优势-->
      <div v-show="tabIndex===2" class="column-me row-center">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">运营经验</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">OPERATIONAL EXPERIENCE</div>
        <div class="ope-title1 margin-top50">
          红尾狐目前全国250+门店，与国内众多连锁商业综合体建立了广泛合作，并已入驻22个省市，在丰富了各综合体业态的同时，
          也为所在综合体带来可观的客流量。
          目前旗下品牌“红尾狐卡丁”已落地的项目有:七彩云南欢乐世界，大连发现王国，王府南阳河亲子游乐园，北京十渡据马乐园，
          保定白洋淀不夜城，萍乡和平农场，西安乐华城欢乐世界，秦汉园欢乐动物城，西安乐华恒业欢乐世界等。
          具备场地运营以及景区运营团队的成功经验!
        </div>
        <img src="../assets/img/img_yyjy.png" class="ope-img1 margin-top40" draggable="false">
        <div class="row-me center-all margin-top100">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left10 margin-right10">
            <div class="title">运营管理</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="subtitle">OPERATION MANAGEMENT</div>
        <div class="yellow-div">
          <img src="../assets/img/bg_yygl.png" class="posAbsoluteAll" draggable="false">
          <div class="posAbsoluteAll">
            <div class="ye-title1">小狐出行——拥有丰富的景区车辆运营管理经验，形成了流程化、标准化的运维规范</div>
            <div class="ye-title2">
              小狐出行拥有完善的研发团队，并与多家高等院校、科研机构进行合作，研发实力雄厚，公司科研及研发团队均来自澳柯玛电动车及相关配套企业、
              技术雄厚。后转战儿童卡丁车行业，经过了卡丁车一代到六代的更新迭代，逐步发展为行业顶尖水平，目前公司凭借生产经验及自主研发能力，
              在景区代步车领域深耕，致力于打造出智能化、高性能的景区代步车。<br>
              小狐出行拥有成熟的景区管理体系、成熟的活动策划经验、成熟的景区运营经验。团队人员运营过青岛世园会，唐山世园会、萍乡和平农场、
              北京十渡拒马乐园、福州欧乐堡等项目，累计参与运营管理项目百余个，策划活动方案数百次。可以有效解决运营中各方面问题，
              不断为景区团队赋能、优化运营模式，让景区代步车运营变得更加科学、高效。
            </div>
          </div>
        </div>
        <div class="column-me margin-top55">
          <div class="card-div-manage column-me row-center">
            <img src="../assets/img/icon_cpaqbz.png" class="img-icon" draggable="false">
            <div class="ma-text1">产品安全保障</div>
            <div class="ma-text2">
              通过系统后台规划行车电子围栏，让游客在安全范围内骑行，超出范围会语音提醒并停车。并与中国平安保险有限公司定制专属的
              保险方案，保障代步车内外的游客安全。
            </div>
          </div>
          <div class="card-div-manage column-me row-center margin-top20">
            <img src="../assets/img/icon_bzhlcjqqy.png" class="img-icon" draggable="false">
            <div class="ma-text1">标准化流程及安全员</div>
            <div class="ma-text2">
              员工配备相应的操作手册，在景区内有序运营。现场安全管控员为游客介绍代步车的操作指引、租还车规范、临停规范。巡查员：
              营业期间，在景区范围内巡游，规范游客骑行和临停的规范，维持标准秩序。
            </div>
          </div>
          <div class="card-div-manage column-me row-center margin-top20">
            <img src="../assets/img/icon_xxty.png" class="img-icon" draggable="false">
            <div class="ma-text1">形象统一</div>
            <div class="ma-text2">
              听从景区的安排，统一服装、服务标准。
            </div>
          </div>
        </div>


      </div>

    </div>
  </div>
  <div class="xiaoHuTravel" v-else>
    <img src="../assets/img/banner_xhcx.png" class="img-banner">
    <div class="main-content">
      <div class="row-me tab-div">
        <div class="row-me center-all left-div">
          <div class="title1">小狐出行</div>
          <div class="title2 margin-left20">XIAOHU TRAVEL</div>
        </div>
        <div class="right-div row-me row-center flex-wrap flex-end flex1">
          <div class="title0" :class="{'title0-active':tabIndex===0}" @click="clickIndex(0)">品牌介绍</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===1}" @click="clickIndex(1)">项目优势</div>
          <div class="tab-line"></div>
          <div class="title0" :class="{'title0-active':tabIndex===2}" @click="clickIndex(2)">运营优势</div>
        </div>
      </div>

      <!--品牌介绍-->
      <div v-show="tabIndex===0">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">智能代步车引领者</div>
            <div class="subtitle">INTELLIGENT SCOOTER LEADER</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="card-div-leader row-me row-center margin-top80">
          <img src="../assets/img/img_xhcx.png" class="img1" draggable="false">
          <div class="flex1 margin-left60 margin-right60 text1">
            “小狐出行”隶属于红尾狐企业旗下品牌板块之一，专注服务于景区代步车业务板块。数年内已深耕22个省市，落地上百余个项目，
            犹如业界的一匹黑马。“小狐出行”致力于景区+物联网模式开发和实施，拥有实力雄厚自主科技研发团队，集合机电工程、汽车设计、
            网络控制系统等各方面人才，在代步车的设计上精益求精，不断创新，为景区提供高性能、高安全性的代步车。后疫情时代，
            代步车作为景区标配二销项目的新趋势，2023年“小狐出行”将在全国景区重点推广!
          </div>
        </div>
        <div class="row-me center-all margin-top80">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">品牌文化</div>
            <div class="subtitle">XIAOHU CULTURE</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="card-div-culture row-me row-center margin-top35">
          <img src="../assets/img/01.png" class="img1" draggable="false">
          <div class="margin-left70">理念：打造景区共享物联网代步车，解锁景区出游新方式</div>
        </div>
        <div class="card-div-culture row-me row-center margin-top20">
          <img src="../assets/img/02.png" class="img1" draggable="false">
          <div class="margin-left70">使命：解决景区痛点，改变游客驾驶体验</div>
        </div>
        <div class="card-div-culture row-me row-center margin-top20">
          <img src="../assets/img/03.png" class="img1" draggable="false">
          <div class="margin-left70">愿景：让游客出游体验更便捷、更舒适</div>
        </div>

      </div>
      <!--项目优势-->
      <div v-show="tabIndex===1">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">项目优势</div>
            <div class="subtitle">PROJECT ADVANTAGES</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <img src="../assets/img/bg_xhcx_1.png" class="img-project" draggable="false">
        <img src="../assets/img/bg_xhcx_2.png" class="img-project" draggable="false">
        <img src="../assets/img/bg_xhcx_3.png" class="img-project" draggable="false">
      </div>
      <!--运营优势-->
      <div v-show="tabIndex===2">
        <div class="row-me center-all margin-top90">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">运营经验</div>
            <div class="subtitle">OPERATIONAL EXPERIENCE</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="ope-title1 margin-top50">
          红尾狐目前全国250+门店，与国内众多连锁商业综合体建立了广泛合作，并已入驻22个省市，在丰富了各综合体业态的同时，
          也为所在综合体带来可观的客流量。
          目前旗下品牌“红尾狐卡丁”已落地的项目有:七彩云南欢乐世界，大连发现王国，王府南阳河亲子游乐园，北京十渡据马乐园，
          保定白洋淀不夜城，萍乡和平农场，西安乐华城欢乐世界，秦汉园欢乐动物城，西安乐华恒业欢乐世界等。
          具备场地运营以及景区运营团队的成功经验!
        </div>
        <img src="../assets/img/img_yyjy.png" class="ope-img1 margin-top40" draggable="false">
        <div class="row-me center-all margin-top100">
          <img src="../assets/img/title_line.png" class="img-line">
          <div class="column-me row-center margin-left60 margin-right60">
            <div class="title">运营管理</div>
            <div class="subtitle">OPERATION MANAGEMENT</div>
          </div>
          <img src="../assets/img/title_line.png" class="img-line">
        </div>
        <div class="yellow-div">
          <img src="../assets/img/bg_yygl.png" class="posAbsoluteAll" draggable="false">
          <div class="posAbsoluteAll">
            <div class="ye-title1">小狐出行——拥有丰富的景区车辆运营管理经验，形成了流程化、标准化的运维规范</div>
            <div class="ye-title2">
              小狐出行拥有完善的研发团队，并与多家高等院校、科研机构进行合作，研发实力雄厚，公司科研及研发团队均来自澳柯玛电动车及相关配套企业、
              技术雄厚。后转战儿童卡丁车行业，经过了卡丁车一代到六代的更新迭代，逐步发展为行业顶尖水平，目前公司凭借生产经验及自主研发能力，
              在景区代步车领域深耕，致力于打造出智能化、高性能的景区代步车。<br>
              小狐出行拥有成熟的景区管理体系、成熟的活动策划经验、成熟的景区运营经验。团队人员运营过青岛世园会，唐山世园会、萍乡和平农场、
              北京十渡拒马乐园、福州欧乐堡等项目，累计参与运营管理项目百余个，策划活动方案数百次。可以有效解决运营中各方面问题，
              不断为景区团队赋能、优化运营模式，让景区代步车运营变得更加科学、高效。
            </div>
          </div>
        </div>
        <div class="row-me row-center margin-top55">
          <div class="card-div-manage column-me row-center flex1">
            <img src="../assets/img/icon_cpaqbz.png" class="img-icon" draggable="false">
            <div class="ma-text1">产品安全保障</div>
            <div class="ma-text2">
              通过系统后台规划行车电子围栏，让游客在安全范围内骑行，超出范围会语音提醒并停车。并与中国平安保险有限公司定制专属的
              保险方案，保障代步车内外的游客安全。
            </div>
          </div>
          <div class="card-div-manage column-me row-center flex1 margin-left20">
            <img src="../assets/img/icon_bzhlcjqqy.png" class="img-icon" draggable="false">
            <div class="ma-text1">标准化流程及安全员</div>
            <div class="ma-text2">
              员工配备相应的操作手册，在景区内有序运营。现场安全管控员为游客介绍代步车的操作指引、租还车规范、临停规范。巡查员：
              营业期间，在景区范围内巡游，规范游客骑行和临停的规范，维持标准秩序。
            </div>
          </div>
          <div class="card-div-manage column-me row-center flex1 margin-left20">
            <img src="../assets/img/icon_xxty.png" class="img-icon" draggable="false">
            <div class="ma-text1">形象统一</div>
            <div class="ma-text2">
              听从景区的安排，统一服装、服务标准。
            </div>
          </div>
        </div>


      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tabIndex: 0,
      titleArray: [],
      isMobile: false,
    }
  },
  // html加载完成之前
  created() {

  },
  // html加载完成后执行。
  mounted() {
    this.isMobile = sessionStorage.getItem('mobile') === '1'
  },
  // 事件方法执行
  methods: {
    clickIndex(index) {
      this.tabIndex = index
    },
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  activated() {

  }
}
</script>

<style lang="scss" scoped>
.xiaoHuTravel-mobile {
  .img-banner {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .main-content {
    padding: 20px;

    .tab-div {
      background: #F4F4F4;
      border-radius: 10px;

      .left-div {
        width: 130px;
        background: #000000;
        border-radius: 10px 0px 0px 10px;
        padding: 10px 0;

        .title1 {
          font-size: 23px;
          color: #FFFFFF;
        }

        .title2 {
          font-size: 15px;
          color: #FFF10C;
          margin-top: 10px;
        }
      }

      .right-div {
        padding: 15px 10px;

        .item-title {
          margin-bottom: 10px;
        }
      }

      .title0 {
        font-size: 18px;
        color: #999999;
        padding: 0 10px;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        &-active {
          color: #666666;
          font-weight: bold;
        }
      }

      .tab-line {
        width: 3px;
        height: 20px;
        background: #999999;
        border-radius: 10px;
      }
    }

    .img-line {
      width: 80px;
    }

    .title {
      font-size: 28px;
      color: #000000;
      letter-spacing: 2px;
      text-align: center;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 18px;
      color: #666666;
    }

    .card-div-leader {
      width: 100%;
      box-shadow: 0px 10px 30px rgba(40, 40, 40, 0.1);

      .img1 {
        width: 100%;
        object-fit: fill;
      }

      .text1 {
        font-size: 20px;
        color: #333333;
        line-height: 35px;
        padding-bottom: 10px;
      }
    }

    .card-div-culture {
      width: 100%;
      box-shadow: 0px 10px 30px rgba(40, 40, 40, 0.1);
      font-size: 20px;
      color: #333333;
      padding: 10px 0;

      .img1 {
        width: 60px;
        object-fit: scale-down;
      }
    }

    .img-project {
      width: 100%;
      object-fit: scale-down;
      margin-top: 20px;
    }

    .ope-title1 {
      font-size: 20px;
      color: #333333;
      line-height: 35px;
    }

    .ope-img1 {
      width: 100%;
      object-fit: scale-down;
    }

    .yellow-div {
      width: 100%;
      height: 800px;
      position: relative;
      margin-top: 50px;

      .ye-title1 {
        font-size: 22px;
        color: #222222;
        font-weight: bold;
        margin: 20px;
      }

      .ye-title2 {
        font-size: 18px;
        color: #74490F;
        line-height: 30px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    .card-div-manage {
      background: #FFFFFF;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
      padding-bottom: 30px;

      .img-icon {
        width: 100px;
        height: 100px;
        object-fit: scale-down;
        margin-top: 20px;
      }

      .ma-text1 {
        font-size: 26px;
        color: #333333;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .ma-text2 {
        font-size: 20px;
        color: #333333;
        line-height: 30px;
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
      }
    }
  }
}

.xiaoHuTravel {
  .img-banner {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .main-content {
    padding: 80px 100px;

    .tab-div {
      background: #F4F4F4;
      border-radius: 10px;

      .left-div {
        width: 330px;
        background: #000000;
        border-radius: 10px 0px 0px 10px;

        .title1 {
          font-size: 23px;
          color: #FFFFFF;
        }

        .title2 {
          font-size: 15px;
          color: #FFF10C;
          margin-bottom: -15px;
        }
      }

      .right-div {
        padding: 25px 30px;

        .item-title {
          line-height: 45px;
        }
      }

      .title0 {
        font-size: 26px;
        color: #999999;
        margin-left: 30px;
        text-align: center;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        &-active {
          color: #666666;
          font-weight: bold;
        }
      }

      .tab-line {
        width: 3px;
        height: 30px;
        margin-left: 30px;
        background: #999999;
        border-radius: 10px;
      }
    }

    .img-line {
      width: 145px;
      object-fit: scale-down;
      margin-bottom: 10px;
    }

    .title {
      font-size: 48px;
      color: #000000;
      letter-spacing: 5px;
      text-align: center;
    }

    .subtitle {
      font-size: 24px;
      color: #666666;
    }

    .card-div-leader {
      width: 100%;
      height: 400px;
      box-shadow: 0px 10px 30px rgba(40, 40, 40, 0.1);

      .img1 {
        width: 630px;
        height: 100%;
        object-fit: cover;
      }

      .text1 {
        font-size: 24px;
        color: #333333;
        line-height: 42px;
      }
    }

    .card-div-culture {
      width: 100%;
      height: 120px;
      box-shadow: 0px 10px 30px rgba(40, 40, 40, 0.1);
      font-size: 34px;
      color: #333333;

      .img1 {
        height: 100%;
        object-fit: cover;
      }
    }

    .img-project {
      width: 100%;
      height: 762px;
      object-fit: cover;
      margin-top: 60px;
    }

    .ope-title1 {
      font-size: 24px;
      color: #333333;
      line-height: 42px;
    }

    .ope-img1 {
      width: 100%;
      height: 554px;
    }

    .yellow-div {
      width: 100%;
      height: 430px;
      position: relative;
      margin-top: 50px;

      .ye-title1 {
        font-size: 40px;
        color: #222222;
        font-weight: bold;
        margin: 65px 60px 30px 60px;
      }

      .ye-title2 {
        font-size: 24px;
        color: #74490F;
        line-height: 42px;
        margin-left: 60px;
        margin-right: 60px;
      }
    }

    .card-div-manage {
      height: 600px;
      background: #FFFFFF;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);

      .img-icon {
        width: 120px;
        height: 120px;
        object-fit: scale-down;
        margin-top: 80px;
      }

      .ma-text1 {
        font-size: 36px;
        color: #333333;
        font-weight: bold;
        margin-top: 50px;
        margin-bottom: 30px;
      }

      .ma-text2 {
        font-size: 24px;
        color: #333333;
        line-height: 42px;
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
      }
    }
  }
}
</style>
